* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}
main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: white;
  color: black;
    height: 100vh;
  overflow-y: auto;
  overflow-x: auto;
}

.top_section {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.icon{
  justify-content: center!important;
  align-items: center!important;
  align-content: center!important;
  display: flex!important;
  width: 20px;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: black;
  gap: 10px;
  padding: 5px 7px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: blue;
  transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}


.active {
  border-right: 4px solid white;
  background: white
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.link:hover >  * {
  color: white;
  fill: white;
}

a {
  text-decoration: none!important;
}

.menu {
  display: flex;
  color: black;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
/*   border-bottom: #fff 0.5px solid;
 */}

 .bgfondo{
  /* background-color: #f0f2f5; */
  background-color: #e9e9e9;
}

.borde-tarjeta {
  box-shadow: -1px 3px 6px 1px rgb(202 212 228);
}

.bordecircular{
  border-radius: 7px;
}


.modal .modal-header > button {
  border: 1px solid black!important;
  padding: 9px!important;
  border-radius: 52px!important;
  background-color: white;
  color: black;
}

.modal-content{
  padding: 12px;
  border-radius: 7px;
  box-shadow: 0px 3px 8px 1px rgb(169 164 164 / 68%);
  background-clip: border-box!important;
}

.modal .modal-header .close span {
  display: block !important;
  color: black;
  /* font-size: 30px; */
}

.modal{
  background: #fbfbfbe6;

}

.centrar
{
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.negrita{
  font-weight: bold;
}

/* img, svg {
  vertical-align: middle;
  width: 25px;
} */

.labelinput{
  font-size: 14px!important;
  margin: 0;
}

.bgboton{
  background:white!important;
  color:blue!important;
  fill:blue;
  border: 1px solid blue;
  
  padding:2px;
}

.bgbotongreen{
  background:rgb(8, 128, 64)!important;
  color:white!important;
  fill:white;
  border: 1px solid blue;
  border-radius: 50%;
  padding:2px;
}

.bgbotondelete{
  background:white!important;
  color:red!important;
  fill:red;
  border: 1px solid red;
  border-radius: 50%;
  padding:2px;
}

.absolute{
  position:absolute;
}

.opacoUpload{
  opacity: 0!important;
}

.opacoUpload:hover{
  opacity: 0.9!important;
}


.hero-image {
  background-image: url("./images/login.png"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}


.textareapublicar{
  width: 100%;
  padding: 12px;
  text-decoration: none;
  background: #f0f2f5!important;
  border-radius: 2px;
  height: 70px !important;
  overflow: auto;
  border: none;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

    resize: none;
}