body {
  font-family:Verdana, Geneva, Tahoma, sans-serif!important;
  font-size: 12px!important;
  text-shadow: 0px .1px 0px rgba(0, 0, 0, 0.65);
/*   letter-spacing: .2px;
 */    height: 100vh;
}


a,input,button,div,select{
  font-size: 12px!important;


}

svg{
  width: 18px!important;
}

.sombra{
  box-shadow: 0px 6px 11px 0px #8f7777;
}

.dashboardicon {
  width: 80px!important;
}

code {
  font-family:Verdana, Geneva, Tahoma, sans-serif!important;}

ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}


.formx div  {
  width: 100%!important;
}
.bordecustom{
  border-right: 1px solid rgb(241, 235, 235);
}